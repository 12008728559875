<template>
  <div class="card mt-4">
    <img v-if="question.illustration" 
        class="card-img-top illustration" 
        :src="question.illustration" 
        alt="illustration" />
    <div class="card-body">
      <h3 class="card-title">{{ question.question }}</h3>
      <div v-if="waitingForAnswer()" class="card-text">
        <ul class="list-group">
          <li
            v-for="(option, index) in question.choices"
            :key="index"
            class="list-group-item list-group-item-action"
            :class="choiceColor(option)"
            @click="checkAnswer(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
      <div v-else>
        <div v-if="this.isCorrect(selectedOption)" class="alert alert-success" role="alert"><h2>Gagné !</h2></div>
        <div v-else class="alert alert-warning" role="alert"><h2>Perdu !</h2></div>
        <div class="card-header">
          {{ question.explaination }}
        </div>
        <button class="btn btn-primary mt-4" @click="nextQuestion()">Suivant</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: Object,
  },
  ///////////////////////////////////////////////////////////////////////
  data() {
    return {
      selectedOption: '',
    };
  },
  ///////////////////////////////////////////////////////////////////////
  methods: {
    isCorrect(option) {
      return option === this.question.correctAnswer
    },
    waitingForAnswer() {
      return this.selectedOption.length == 0
    },
    checkAnswer(option) {
      if (!this.selectedOption.length) {
        this.selectedOption = option
      }
    },
    nextQuestion() {
      this.$emit("answer", this.isCorrect(this.selectedOption))
      this.selectedOption = ''
    },
    choiceColor(option) {
      if (this.selectedOption != option)
        return ''
      if (this.isCorrect(option))
        return 'bg-success'
      return 'bg-danger'
    }
  }
};
</script>

<style scoped>
ul.list-group {
  /* padding: 0px 5px 0px 5px; */
  margin-left: 25%;
  margin-right: 25%;
}

</style>
