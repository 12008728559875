<template>
  <div class="container text-center">
    <h1 class="mt-5" @click="Retry">Quiz Ouganda</h1>
    <div class="quiz-item" v-if="currentQuestion">
      <quiz-item
        :question="currentQuestion"
        @answer="handleAnswer"
      ></quiz-item>
    </div>
    <div v-else>
      <div class="border m-4">
        <h4 class="mt-4">votre score: {{ correctAnswers }} / {{ questions.length }}</h4>
        <p>{{ evaluation }}</p>
      </div>
      <MvCoupon />
    </div>
  </div>
</template>

<script>
import QuizItem from "./QuizItem.vue";
import quizData from "@/assets/quiz-data.json";
import MvCoupon from './MvCoupon.vue'

export default {
  components: {
    QuizItem,
    MvCoupon
},
  data() {
    return {
      questions: [], // Store your questions here
      currentQuestionIndex: 0,
      correctAnswers: 0,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex];
    },
    evaluation() {
      if (this.correctAnswers == this.questions.length)
        return "Bravo ! Vous êtes un expert du chocolat.";
      else if (this.correctAnswers > (this.questions.length / 2))
        return "Bravo ! Vous maîtrisez bien la fève de cacao.";
      else if (this.correctAnswers > 1)
        return "Vous êtes sur la voie pour maîtriser les secrets du Cacao.";
      return "Ne vous découragez pas : Un carré peut-être pour vous remettre ?"
    }
  },
  methods: {
    Retry() {
      this.currentQuestionIndex = 0
      this.correctAnswers = 0
      this.saveProgress();
    },
    handleAnswer(isCorrect) {
      if (isCorrect) {
        this.correctAnswers++;
      }
      this.currentQuestionIndex++;
      this.saveProgress();
    },
    saveProgress() {
      localStorage.setItem("quizProgress", this.currentQuestionIndex);
      localStorage.setItem("quizAnswers", this.correctAnswers);
    },
  },
  mounted() {
    // Load questions from the JSON file
    this.questions = quizData;

    const savedProgress = localStorage.getItem("quizProgress");
    if (savedProgress) {
      this.currentQuestionIndex = parseInt(savedProgress);
    }
    const savedScore = localStorage.getItem("quizAnswers");
    if (savedScore) {
      this.correctAnswers = parseInt(savedScore);
    }
  },
};
</script>

<style scoped>
.quiz-item {
  /* max-width: 400px; */
  /* flex: auto; */
}
</style>
